import Fetcher, { FetcherRequestOptions, FetcherResponse, isResponseError } from 'javascript/lander/utils/fetcher'
import { StripeSessionData, SubmitPayload, SubmitResponse } from './types'
import { renderedHref, renderedPath } from '../Utils/checkout-path'
import { StripeError } from '@stripe/stripe-js'

export function makeApiFetcher<T>(options: { headers?: HeadersInit } & FetcherRequestOptions): Fetcher<T> {
  const { headers, ...requestOptions } = options
  const fetcher = new Fetcher<T>({
    debug: true,
    pathPrefix: '/user_pages/api/v1/',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers ?? {}),
    },
    requestOptions: {
      retries: 3,
      timeoutMS: 30000,
      timeoutAfterRetrial: 1000,
      shouldCaptureServerError: true,
      convertThrowToErrorResponse: true,
      ...requestOptions,
    },
  })

  return fetcher
}

export function makeCurrentPageFetcher<T>(options: { headers?: HeadersInit } & FetcherRequestOptions): Fetcher<T> {
  const { headers, ...requestOptions } = options
  const fetcher = new Fetcher<T>({
    debug: true,
    pathPrefix: window.location.origin + renderedPath(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers ?? {}),
    },
    requestOptions: {
      retries: 3,
      timeoutMS: 30000,
      timeoutAfterRetrial: 1000,
      convertThrowToErrorResponse: true,
      onlyOneInflightRequest: true,
      ...requestOptions,
    },
  })
  return fetcher
}

export const stripeSessionApiFetcher = makeApiFetcher<StripeSessionData>({
  onlyOneInflightRequest: true,
  simulateNetworkError: false,
})

export const stripeSubmitFetcher = new Fetcher<SubmitResponse>({
  debug: true,
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CF2-POST-TYPE': 'submit',
  },
  requestOptions: {
    retries: 3,
    timeoutMS: 60000,
    timeoutAfterRetrial: 1000,
    shouldCaptureServerError: true,
    convertThrowToErrorResponse: true,
    onlyOneInflightRequest: true,
    canAbort: false,
  },
})

export function isStripeError(error: any): error is StripeError {
  if (typeof error === 'object') {
    const stripeError = error as StripeError
    if (stripeError?.code && stripeError?.message) {
      return true
    }
  }

  return false
}

export async function postSubmit(payload: SubmitPayload): Promise<FetcherResponse<SubmitResponse>> {
  const response = await stripeSubmitFetcher.fetch(
    renderedHref(),
    {
      credentials: 'same-origin',
      body: JSON.stringify(payload),
    },
    {}
  )

  if (isResponseError(response)) {
    return response
  } else {
    const body = await response.json()
    if (response.ok) {
      return body as FetcherResponse<SubmitResponse>
    } else if (response.status === 422) {
      // NOTE: Would be better is the backend followed the same data structure
      // as the stripe errors so we wouldn't have to translate anythin in here.
      // I am doing this transformation to simplify the code paths when handling
      // responses afterwards.
      const { error, error_details } = body as Server422Response
      const { code } = error_details ?? {}
      return {
        error: {
          code,
          message: error,
        },
      } as FetcherResponse<SubmitResponse>
    }
  }
}

export type Server422Response = {
  error: string
  error_details: { code: string }
}
